import type { RouteRecordRaw } from 'vue-router';

const defaultPsfRole = 'default-roles-psf';
const defaultPsfAudience = 'psf-ava-web-ui';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('@ui/overview/OverviewView.vue'),
    meta: { requiresAuth: true, requiredRoles: [defaultPsfRole], requiredAuds: [defaultPsfAudience] },
  },
  {
    path: '/contract-conditions/:id',
    name: 'contract-conditions',
    component: () => import('@ui/contract-conditions/ContractConditionsView.vue'),
    meta: { requiresAuth: true, requiredRoles: [defaultPsfRole], requiredAuds: [defaultPsfAudience] },
  },
  {
    /**
     * Global error page
     * In case the user is not allowed to access a certain page they will be redirected here.
     */
    path: '/error',
    name: 'Error',
    meta: { requiresAuth: false },
    component: () => import('@ui/error/ErrorView.vue'),
  },
  {
    /**
     * Logout page
     * When the user logged out while visiting a restricted page, they will be redirected here.
     */
    path: '/logged-out',
    name: 'loggedOut',
    meta: { requiresAuth: false },
    component: () => import('@ui/logged-out/LoggedOutView.vue'),
  },
];
