export const Messagetypes = {
  WARNING: 'WARNING',
  INFO: 'INFO',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
} as const;

export type Keys<T> = keyof T;

export type MessageType = Keys<typeof Messagetypes>;

export type ToastColorsRecord = Record<MessageType, "danger" | "warning" | "neutral" | "success">; 

export const ToastColors: ToastColorsRecord = { 
  ERROR: "danger", 
  WARNING: "warning", 
  INFO: "neutral", 
  SUCCESS: "success", 
};

export enum Error {
  ABORTERROR = 'Reason: Cancel old Request',
}
