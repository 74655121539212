import { UserService } from '@services/utility/UserService';
import { NavigationGuard } from 'vue-router';

// Factory function for the login guard
export const createLoginGuard = () => {
  const loginGuard: NavigationGuard = async (to) => {
    // ensure that stored user has been loaded if available (independent of whether route requires auth)
    await UserService.loadStoredUser();

    const isReturningFromLoginRedirect = to.query.code !== undefined && to.query.state !== undefined;
    // when the route is excluded from auth, we allow access without a login.
    // this is skipped when the route contains query parameters from the oAuth redirect
    // so the login can be completed.
    if (!to.meta.requiresAuth && !isReturningFromLoginRedirect) {
      return true;
    }

    // Get the route requested by the user before the login redirect was initiated.
    // This will not be defined if the user is already logged in.
    try {
      const previousRequestedRoute = await UserService.login(to.fullPath);

      if (previousRequestedRoute) {
        return { path: previousRequestedRoute, replace: true };
      }

      return true;
    } catch (error) {
      // optionally show a message to the user that the login failed

      // if route requires auth, the next route guard should handle the not logged-in user, so we return true here
      // otherwise we need to make sure to remove the invalid auth query params to not start into an infinite auth loop
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { code, state, scope, ...sanitizedQuery } = to.query;
      return to.meta.requiresAuth ? true : { path: to.path, query: sanitizedQuery };
    }
  };

  return loginGuard;
};
