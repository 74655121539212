import type { NumberFormat, NumberFormats } from '@intlify/core-base';

const numberFormats: NumberFormats<NumberFormat, string> = {
  en: {
    currency: { style: 'currency', currency: 'USD' },
  },
  de: {
    currency: { style: 'currency', currency: 'EUR', currencyDisplay: 'code' },
  },
};
export default numberFormats;
