<script setup lang="ts">
import { computed, ref } from 'vue';
import { ScuTopBar, ScuIcon, ScuTopBarUser, ScuAvatar, ScuLink } from '@scu/vue';
import type { NavItemDefinition } from '@scu/core-ui/dist/types/components/scu-top-bar/types';
import { useMsgFormatter } from '@i18n';
import { useRouter } from 'vue-router';
import { useUserStore } from '@stores/userStore';
import { UserService } from '@services/utility/UserService';
import { profile, login, logout, settings } from '@scu/icons';
import { ScuTopBarCustomEvent } from '@scu/core-ui';
import sort from "@sit-onyx/icons/sort.svg?raw";
import ManageFilterModal from '@ui/filter/ManageFilterModal.vue';

defineProps<{
  onSettingsClick: () => void;
}>();

const { t } = useMsgFormatter();
const userStore = useUserStore();
const router = useRouter();
const isManageFilterDialogVisible = ref(false);

const version = __VITE_APP_VERSION__;
const avaAVFURL = 'https://dev.ava.psf.odj.cloud/';

const navItems = computed<NavItemDefinition[]>(() => [
  { label: t('topBar.navigation.itemCreation'), href: avaAVFURL },
  { label: t('topBar.navigation.contractConditionsCreation'), href: '/', active: true },
]);

const onNavItemClick = (event: ScuTopBarCustomEvent<NavItemDefinition>) => {
  if (event?.detail) {
    const navItem: NavItemDefinition = event.detail;
    if (navItem.href !== undefined) {
      if (navItem.href.startsWith('/')) {
        router.push(navItem.href);
      } else {
        window.location.href = navItem.href;
      }
    }
  }
};

const onLoginClick = async () => {
  try {
    await UserService.login();
  } catch (error) {
    return { id: 'pages.error.login', values: { error: error } };
  }
};

const onManageFilterClick = () => {
  isManageFilterDialogVisible.value = true;
};

const onCancelModal = () => {
  isManageFilterDialogVisible.value = false;
};
</script>

<template>
  <ManageFilterModal
    v-if="isManageFilterDialogVisible"
    :onCancel="onCancelModal"
    :hideAcceptButton="true"
  />
  <ScuTopBar data-test-id="topbar" app-name="KKF" :navItems="navItems" @scunavitemclick="onNavItemClick">
    <ScuTopBarUser
      v-if="userStore.user !== undefined"
      slot="user-options"
      :icon="profile"
      :userName="userStore.user.profile.name ?? ''"
      :appVersion="'App version ' + version"
    >
      <ScuAvatar slot="avatar" />
      <ScuLink mode="list" @scuclick="onManageFilterClick">
        <ScuIcon :src="sort"></ScuIcon>{{ t('topBar.filterManager') }}
      </ScuLink>
      <ScuLink mode="list" @scuclick="onSettingsClick">
        <ScuIcon :src="settings"></ScuIcon>{{ t('topBar.settings') }}
      </ScuLink>
      <ScuLink mode="list" color="var(--red-5)" @scuclick="UserService.logout">
        <ScuIcon :src="logout" />{{ t('topBar.logout') }}
      </ScuLink>
    </ScuTopBarUser>
    <ScuTopBarUser
      v-else
      slot="user-options"
      :icon="profile"
      :userName="t('topBar.login')"
      :appVersion="'App version ' + version"
      hide-user-info
    >
      <ScuLink mode="list" @scuclick="onSettingsClick">
        <ScuIcon :src="settings"></ScuIcon>{{ t('topBar.settings') }}
      </ScuLink>
      <ScuLink mode="list" color="var(--green-5)" @scuclick="onLoginClick">
        <ScuIcon :src="login" />{{ t('topBar.login') }}
      </ScuLink>
    </ScuTopBarUser>
  </ScuTopBar>
</template>
