import { dbFactory } from '@services/storage/dbFactory';
import { UserPreferencesStore } from '@customTypes/User';
import { Settings, UserSettings } from '@customTypes/Settings';

function parseIntoUserPersonalSettings(input: any): UserSettings | undefined {
  if (input) return JSON.parse(JSON.stringify(input)) as UserSettings;
  return undefined;
}

async function loadUserSettings(): Promise<Settings | undefined> {
  const dbKey = 'settings';
  const db = await dbFactory.getDb();
  const result = await db.get(UserPreferencesStore, dbKey);
  return parseIntoUserPersonalSettings(result)?.settings;
}

async function upsertUserSettings(settings: Settings) {
  const dbKey = 'settings';
  const db = await dbFactory.getDb();
  const dbEntry = {
    userID: dbKey,
    settings: settings,
  };
  const serializedSettings = JSON.parse(JSON.stringify(dbEntry));
  await db.put(UserPreferencesStore, serializedSettings);
}

export const userSettingsStorage = { loadUserSettings, upsertUserSettings };
