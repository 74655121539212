import type { DateTimeFormat, DateTimeFormats } from '@intlify/core-base';

const formats: DateTimeFormat = {
  date: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  dateTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
};

const dateTimeFormats: DateTimeFormats<DateTimeFormat, string> = {
  en: formats,
  de: formats,
};

export default dateTimeFormats;
