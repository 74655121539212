<script setup lang="ts">
import { OnyxDialog, OnyxHeadline, OnyxButton, OnyxIcon } from 'sit-onyx';
import { useMsgFormatter } from '@i18n';
import type { MessageOrId } from '@i18n/types';
const { t } = useMsgFormatter();

const props = defineProps<{
  onAccept?: () => void;
  onCancel: () => void;
  headline: MessageOrId;
  acceptButtonLabel?: MessageOrId;
  cancelButtonLabel?: MessageOrId;
  disabledAcceptButton?: boolean;
  hideCancelButton?: boolean;
  hideAcceptButton?: boolean;
  icon?: string;
}>();
</script>
<template>
  <OnyxDialog label="" density="compact" open modal @close="onCancel">
    <div class="container">
      <div class="header">
        <div class="left-column">
          <OnyxIcon v-if="props.icon" :icon="props.icon ?? ''" />
        </div>
        <div class="right-column">
          <OnyxHeadline is="h2">{{ t(headline) }}</OnyxHeadline>
        </div>
      </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
      <div class="footer">
        <OnyxButton
          v-if="!props.hideCancelButton"
          :label="t(props.cancelButtonLabel ?? 'general.abort')"
          v-on:click="props.onCancel"
          color="neutral"
          class="footer-button"
        ></OnyxButton>
        <OnyxButton
          v-if="!props.hideAcceptButton"
          :disabled="props.disabledAcceptButton ?? false"
          :label="t(props.acceptButtonLabel ?? 'general.apply')"
          v-on:click="props.onAccept"
          class="footer-button"
        ></OnyxButton>
      </div>
    </div>
  </OnyxDialog>
</template>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header {
  background-color: #e3eaf0;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-column {
  display: flex;
  align-items: center;
}
.right-column {
  flex: 1;
  display: flex;
  align-items: center;
}
.icon {
  width: 30px; /* Adjust size as needed */
  height: 30px;
}
.content {
  flex: 1;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 100px;
  min-width: 20vw;
  overflow: scroll;
}
.footer {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.footer-button {
  min-width: 100px;
}
</style>
