import { APIError } from '@api/APIError';
import { MessageType, Messagetypes } from '@customTypes/ToastMessages';
import { useErrorStore } from '@stores/errorStore';

const catchAPIError = (err: APIError): undefined => {
  useErrorStore().apiError = err;
  console.error(err.message);
};

const catchDecimalError = (err: Error): undefined => {
  useErrorStore().decimalError = err;
  console.error(err.message);
};

const dismissError = () => (useErrorStore().apiError = undefined);

const getErrorType = (status: number): MessageType => {
  switch (true) {
    case status === 422: // 422 Unprocessable Entity
    case status === 429: // 429 Too Many Requests
      return Messagetypes.WARNING; // Specific Client Warnings

    case status >= 500:
      return Messagetypes.ERROR; // Server Errors

    case status >= 400:
      return Messagetypes.ERROR; // General Client Errors

    case status >= 300:
      return Messagetypes.INFO; // Redirection Messages

    case status >= 200:
      return Messagetypes.SUCCESS; // Success Messages

    case status >= 100:
      return Messagetypes.INFO; // Informational Messages

    default:
      return Messagetypes.INFO; // Fallback for unexpected status codes
  }
};

export const ErrorService = {
  catchAPIError,
  dismissError,
  catchDecimalError,
  getErrorType,
};
