import { Log } from 'oidc-client-ts';
import { environment } from './environments/environment';

/** Global app configuration. */
export const config = {
  i18n: {
    /**
     * Mapping of BCP 47 language codes to the written name of the language and the implementing local file inside i18n/locales
     */
    locales: {
      de: ['Deutsch', 'de.json'],
      en: ['English', 'en.json'],
    } as Record<string, [string, string]>,
    fallbackLocale: 'de' as const,
  },
  auth: {
    authority: environment.AUTH_IDP,
    clientId: environment.AUTH_CLIENT_ID,
    redirectUri: window.location.origin,
    useSilentRefresh: true,
    disabled: process.env.VUE_APP_AUTH_DISABLED === 'true',
    logLevel: process.env.VUE_APP_NODE_ENV === 'production' ? Log.ERROR : Log.DEBUG,
    redirectedLogoutUrl: `${environment.API_HOST}/logged-out`,
  },
  env: {
    /** The base URL the application bundle will be deployed at. */
    baseUrl: process.env.VUE_APP_BASE_URL,
    kkfGatewayUrl: environment.KKF_GATEWAY_URL,
    bffGatewayUrl: environment.BFF_GATEWAY_URL,
    avfGatewayUrl: environment.AVF_GATEWAY_URL,
  },
};
