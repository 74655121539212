import { Locale, LocalLanguages } from '@customTypes/LocalLanguages';
import { UiLayouts } from '@customTypes/Settings';
import { changeLocale } from '@i18n';
import { useUserStore } from '@stores/userStore';

const setLocalLanguage = (language: Locale) => {
  useUserStore().settings.localLanguage = language;
  switch (language) {
    case LocalLanguages.EnglishGB:
      changeLocale('en');
      break;
    case LocalLanguages.EnglishUS:
      changeLocale('en');
      break;
    default:
      changeLocale('de');
  }
};

const setCountry = (country: string) => {
  useUserStore().settings.negotiationCountry = country;
};

const setUiLayout = (layout: number) => {
  switch (layout) {
    case UiLayouts.core_ui:
      useUserStore().settings.layout = UiLayouts.core_ui;
      break;
    case UiLayouts.onyx_ui:
      useUserStore().settings.layout = UiLayouts.onyx_ui;
      break;
    default:
      useUserStore().settings.layout = UiLayouts.core_ui;
  }
};

export const SettingsService = { setLocalLanguage, setUiLayout, setCountry };
