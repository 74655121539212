// errorStore.ts
import { APIError } from '@api/APIError';
import { defineStore } from 'pinia';
import { ref } from 'vue';

type ErrorState = {
  apiError?: APIError;
  decimalError?: Error;
};

export const useErrorStore = defineStore('errorStore', () => {
  const apiError = ref<APIError|undefined>();
  const decimalError = ref<undefined|Error>();

  // Actions to set errors
  const setApiError = (error?: APIError) => {
   apiError.value = error;
  };

  const setDecimalError = (error?: Error) => {
    decimalError.value = error;
  };

  return {   
    apiError,
    decimalError
  };
});
