import { config } from '@config';
import { useUserStore } from '@stores/userStore';
import { Log, Logger, UserManager, ErrorResponse } from 'oidc-client-ts';

let userManager: UserManager | undefined;

const getUserManager = () => {
  if (!userManager) {
    Log.setLogger(console);
    Log.setLevel(config.auth.logLevel);

    userManager = new UserManager({
      authority: config.auth.authority,
      client_id: config.auth.clientId,
      redirect_uri: config.auth.redirectUri,
      loadUserInfo: true,
      automaticSilentRenew: config.auth.useSilentRefresh,
      // 'openid' scope is required to receive the id_token - required for signout with redirect - as part of login response
      scope: 'openid profile psf roles',
      response_type: 'code',
      extraTokenParams: {
        // this affects that the access token is not encrypted, so it includes user information that the backend can use without decrypting it
        // resourceServer: 'IdentityProviderRSUE',
      },
    });
  }
  return userManager;
};

const isTokenExpired = () => {
  const now = Number((Date.now() / 1000).toFixed(0));
  if (useUserStore().user?.expires_at) {
    return now > (useUserStore().user?.expires_at ?? 0);
  }
  return false;
};

const refreshToken = async () => {
  const userManager = getUserManager();
  try {
    useUserStore().user = (await userManager.signinSilent()) ?? undefined;
  } catch (error) {
    if ((error as Error | undefined)?.message === 'No state in response') {
      await userManager.signinRedirect({ state: '/' });
      return;
    }
    if (
      (error as ErrorResponse).error === 'invalid_grant' &&
      ((error as ErrorResponse).error_description === 'Token is not active' ||
        (error as ErrorResponse).error_description === "Session doesn't have required client")
    ) {
      await userManager.removeUser();
      await userManager.signinRedirect({ state: '/' });
      return;
    }
    await userManager.clearStaleState();
    throw error;
  }
};

const login = async (currentRoute = '/') => {
  if (config.auth.disabled) return;

  await loadStoredUser();
  if (useUserStore().user != null) return; // If a user is already defined inside the UserManager no login is needed

  const um = getUserManager();

  try {
    // If no user is found, the redirect callback is executed to check if the session already contains the response from a login redirect
    const _user = await um.signinRedirectCallback();
    useUserStore().user = _user;
    return _user.state as string;
  } catch {
    // If no login was attempted beforehand a login redirect to SIAM is executed.
    await um.signinRedirect({ state: currentRoute });
  }
};

const loadStoredUser = async () => {
  const userStore = useUserStore();
  if (config.auth.disabled) return;
  const um = getUserManager();

  try {
    userStore.user = (await um.getUser()) ?? undefined;
  } catch (error) {
    Logger.warn('An error occurred while loading the stored user. Logging out.');
    logout();
  }
};

const logout = async () => {
  const um = getUserManager();
  um.removeUser();
  const id_token = useUserStore().user?.id_token;
  useUserStore().user = undefined;
  if (id_token) {
    await um.signoutRedirect({ post_logout_redirect_uri: config.auth.redirectedLogoutUrl, id_token_hint: id_token });
  } else {
    Logger.warn('No sign out redirect because there is no user.');
  }
};

const listUserCountries = (): string[] => {
  const userStore = useUserStore();
  if (userStore.user?.profile?.psf_custom_attributes) {
    let countries: string[];
    if (Array.isArray(userStore.user?.profile.psf_custom_attributes)) {
      if (userStore.user?.profile.psf_custom_attributes.length > 0) {
        countries = userStore.user?.profile.psf_custom_attributes[0].countries ?? [];
      } else {
        countries = [];
      }
    } else {
      countries = userStore.user?.profile.psf_custom_attributes.countries ?? [];
    }
    if (countries.length > 0) {
      return countries;
    }
  }
  return [];
};

export const UserService = { login, loadStoredUser, logout, isTokenExpired, refreshToken, listUserCountries };
