<script setup lang="ts">
import { useMsgFormatter } from '@i18n';
import ModalDialog from '@ui/shared/Dialog.vue';
import { computed, ref } from 'vue';
import edit from '@sit-onyx/icons/edit.svg?raw';
import trash from '@sit-onyx/icons/trash.svg?raw';
import star from '@sit-onyx/icons/star.svg?raw';
import { useFilterStore } from '@stores/filterStore';
import { OnyxTable, OnyxIconButton, OnyxHeadline, OnyxEmpty } from 'sit-onyx';
import triangleWarning from "@sit-onyx/icons/triangle-warning.svg?raw";

const { t } = useMsgFormatter();
const filterStore = useFilterStore();
const isFilterDelete = ref<boolean>(false);
const titeltoDeleted = ref<string>('');

const props = defineProps<{
  onCancel: () => void;
}>();

const filterEntries = computed(() => { 
	return filterStore.userPersonalFilters?.filters;
});

const onCancel = () => {
  props.onCancel();
};

const onFilterDelete = (title: string) => {
	titeltoDeleted.value = title;
  isFilterDelete.value = true;
};

const onCancelFilterDelete = () => {
  isFilterDelete.value = false;
	titeltoDeleted.value = "";
};

const onAcceptFilterDeletion = async () => {
	await filterStore.deleteFilter(titeltoDeleted.value);
	isFilterDelete.value = false;
	titeltoDeleted.value = "";
};
</script>

<template>
	<ModalDialog
    v-if="isFilterDelete"
		:headline="t('filterManager.confirmModal.title')"
    :onAccept="onAcceptFilterDeletion"
    :onCancel="onCancelFilterDelete"
	>
	<template #content>
		<div class="delete-confirmation">
			<OnyxIconButton 
					:icon="triangleWarning" 
					label="Warning"
					density="compact" 
					color="danger" 
			/>
			<OnyxHeadline is="h3" class="form-output">
              {{
                t({ id: 'filterManager.confirmModal.message', values: { filterName: titeltoDeleted } })
              }}</OnyxHeadline
      >
		</div>
	</template>
	</ModalDialog>
  <ModalDialog
    :headline="'filterManager.Heading'"
    :isVisible="true"
		:hideAcceptButton="true"
		:onAccept="onCancel"
    :onCancel="onCancel"
  >
    <template #content>
      <div class="modal-container">
					<OnyxTable data-test-id="filter-table" class="filter-table" density="compact" :striped="true">
							<tr v-for="entry in filterEntries" :key="entry.title">
								<td class="icon-column">
										<OnyxIconButton v-if="entry.isDefault"
											:icon="star"
											label="Star"
											density="compact"
											color="primary"
										/>
								</td>
								<td class="title-column">{{ entry.title }}</td>
								<td class="icon-column">
									<OnyxIconButton
										:icon="edit"
										label="Edit"
										@click="true"
										density="compact"
										color="neutral"
									/>
								</td>
								<td class="icon-column">
									<OnyxIconButton
										:icon="trash"
										label="Trash"
										@click="onFilterDelete(entry.title)"
										density="compact"
										color="danger"
									/>
								</td>
							</tr>
						<template #head>
							<tr>
								<th scope="col"></th>
								<th scope="col">{{ t('filterManager.header.filterTitle') }}</th>
								<th scope="col"></th>
								<th scope="col"></th>
							</tr>
						</template>
						<template #empty>
							<OnyxEmpty> {{ t('filterManager.table.noData') }} </OnyxEmpty>
						</template>
					</OnyxTable>
        </div>
    </template>
  </ModalDialog>
</template>
<style lang="scss" scoped>
.modal-container {
  width: 40vw;
  height: 50vh;
}
.filter-table {
  margin-top: 10px;
}
.no-wrap {
  white-space: nowrap;        
  overflow: hidden;           
  text-overflow: ellipsis;                
}
.delete-confirmation {
  display: flex;
	align-items: center;
	width: 30vw;
  height: 20vh;
	.warning-icon { margin-right: 10px; }
}
.form-output {
  padding: 5px;
}
.icon-column {
	width: 25px;
}
.title-column {
	width: auto;
}
</style>
