import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { SiamUser } from '@customTypes/User';
import { LocalLanguages } from '@customTypes/LocalLanguages';
import { Settings, UiLayouts } from '@customTypes/Settings';
import { userSettingsStorage } from '@services/storage/userSettingsStore';
import { SettingsService } from '@services/utility/SettingsService';

export const useUserStore = defineStore('userStore', () => {
  const user = ref<SiamUser | undefined>(undefined);
  const settings = ref<Settings>({
    localLanguage: LocalLanguages.German,
    layout: UiLayouts.onyx_ui,
    negotiationCountry: '',
  });

  async function loadUserSettings() {
    settings.value =
      (await userSettingsStorage.loadUserSettings()) ??
      <Settings>{ localLanguage: 'de-DE', layout: 1, negotiationCountry: useUserStore().user?.profile.domain };
    SettingsService.setCountry(settings.value.negotiationCountry);
    SettingsService.setLocalLanguage(settings.value.localLanguage);
    SettingsService.setUiLayout(settings.value.layout);
  }

  async function addUserSettings(userSetting: Settings) {
    await userSettingsStorage.upsertUserSettings(userSetting);
    loadUserSettings();
  }

  return {
    user,
    settings,
    loadUserSettings,
    addUserSettings,
  };
});
