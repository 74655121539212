import './assets/index.scss';

import { config } from '@config';
import { createApp } from 'vue';
import { applyPolyfills, defineCustomElements } from '@scu/core-ui/dist/loader';
import App from './App.vue';
import { changeLocale, createI18n, getUserPreferredLocale } from '@i18n';
import { createRouter, createWebHistory } from 'vue-router';
import { createLoginGuard } from './router/loginGuard';
import { createAuthorizationGuard } from './router/authorizationGuard';
import { routes } from './router';
import { SettingsService } from '@services/utility/SettingsService';
import { LocalLanguages } from '@customTypes/LocalLanguages';
import { createPinia } from 'pinia';

import { createOnyx } from 'sit-onyx';
import 'sit-onyx/style.css';
import 'sit-onyx/themes/lidl-light.css';
import { useUserStore } from '@stores/userStore';

(async () => {
  // Initializing CoreUI
  await applyPolyfills();
  await defineCustomElements();

  const pinia = createPinia();

  // Initializing i18n
  const i18n = createI18n(config.i18n);
  const locale = getUserPreferredLocale();
  await changeLocale(locale ?? config.i18n.fallbackLocale);

  const router = createRouter({
    history: createWebHistory(config.env.baseUrl),
    routes,
  });
  const onyx = createOnyx();

  const app = createApp(App).use(router).use(i18n).use(onyx).use(pinia);

  router.beforeEach(createLoginGuard());
  router.beforeEach(createAuthorizationGuard());
  await router.isReady();
  useUserStore().loadUserSettings();
  //SettingsService.setLocalLanguage(locale != 'de' ? LocalLanguages.EnglishUS : LocalLanguages.German);

  app.mount('#root');
})();
