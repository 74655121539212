<script lang="ts" setup>
import { ref, computed } from 'vue';
import { SettingsService } from '@services/utility/SettingsService';
import { LocalLanguages } from '@customTypes/LocalLanguages';
import { useMsgFormatter } from '@i18n';
import { useUserStore } from '@stores/userStore';
import { OnyxButton, OnyxDialog, OnyxRadioGroup, OnyxSelect } from 'sit-onyx';
import ModalDialog from '@ui/shared/Dialog.vue';
import { UserService } from '@services/utility/UserService';
import { UiLayouts } from '@customTypes/Settings';

const { t } = useMsgFormatter();
const userStore = useUserStore();

const props = defineProps<{
  onAccept: () => void;
  onCancel: () => void;
}>();

const currentNegotationCountry = ref(userStore.settings.negotiationCountry);
const currentLocalLanguage = ref(userStore.settings.localLanguage);
const currentLayout = ref(userStore.settings.layout);

const settingsNotChanged = computed(() => {
  return (
    userStore.settings.localLanguage === currentLocalLanguage.value &&
    userStore.settings.negotiationCountry === currentNegotationCountry.value &&
    userStore.settings.layout === currentLayout.value
  );
});

const designOptions = [
  { label: 'Core UI', value: UiLayouts.core_ui },
  { label: 'Onyx', value: UiLayouts.onyx_ui },
];
const languageOptions = [
  { value: LocalLanguages.German, label: 'DE' },
  { value: LocalLanguages.EnglishGB, label: 'EN-GB' },
  { value: LocalLanguages.EnglishUS, label: 'EN-US' },
];

const onAcceptClicked = () => {
  props.onAccept();
  SettingsService.setLocalLanguage(currentLocalLanguage.value);
  SettingsService.setCountry(currentNegotationCountry.value);
  SettingsService.setUiLayout(currentLayout.value);
  userStore.addUserSettings(userStore.settings);
};

const onCancelledClicked = () => {
  props.onCancel();
};

const countryOptions = computed(() => {
  return UserService.listUserCountries().map((entry) => ({
    value: entry,
    label: entry,
  }));
});
</script>
<template>
  <ModalDialog
    :headline="'topBar.settings'"
    :isVisible="true"
    :onAccept="onAcceptClicked"
    :onCancel="onCancelledClicked"
    :disabledAcceptButton="settingsNotChanged"
  >
    <template #content>
      <div class="modal-container">
        <OnyxRadioGroup
          density="compact"
          label="Layout"
          :hideLabel="false"
          direction="horizontal"
          v-model="currentLayout"
          :options="designOptions"
        />
        <OnyxSelect
          density="compact"
          :label="t('settings.userCountry')"
          listLabel="country options"
          :options="countryOptions"
          v-model="currentNegotationCountry"
          placeholder="Placeholder..."
        />
        <OnyxSelect
          density="compact"
          :label="t('settings.language')"
          listLabel="language options"
          :options="languageOptions"
          v-model="currentLocalLanguage"
          placeholder="Placeholder..."
        />
      </div>
    </template>
  </ModalDialog>
</template>
<style lang="scss" scoped>
.modal-container {
  width: 20vw;
  height: 30vh;
}
</style>
