import { dbFactory } from '@services/storage/dbFactory';
import { UserPersonalFilters, UserPreferencesStore } from '@customTypes/User';
import { PersonalFilter } from '@customTypes/FilterTypes';
import { useUserStore } from '@stores/userStore';


function getUserName (): string {
  return useUserStore().user?.profile.preferred_username ?? '';
}

function parseIntoUserPersonalFilters(input: any): UserPersonalFilters | undefined {
  if (input) return JSON.parse(JSON.stringify(input)) as UserPersonalFilters;
  return undefined;
}
export async function loadFilters(): Promise<UserPersonalFilters | undefined> {
  const db = await dbFactory.getDb();
  const userName = getUserName();
  const result = await db.get(UserPreferencesStore, userName);
  return parseIntoUserPersonalFilters(result);
}

async function saveFilters(filters: UserPersonalFilters): Promise<void> {
  const db = await dbFactory.getDb();
  const serializedFilters = JSON.parse(JSON.stringify(filters));
  await db.put(UserPreferencesStore, serializedFilters);
}

async function getDefaultFilter(userFilter?: UserPersonalFilters): Promise<PersonalFilter | undefined> {
  const db = await dbFactory.getDb();
  if (userFilter) {
    return userFilter.filters.find((filter) => filter.isDefault);
  }
  const userName = getUserName();
  const result = await db.get(UserPreferencesStore, userName);
  if (result) {
    userFilter = parseIntoUserPersonalFilters(result);
    return userFilter?.filters.find((filter) => filter.isDefault);
  }
  return undefined;
}

async function findFilterByTitle(title: string, userFilter?: UserPersonalFilters): Promise<PersonalFilter | undefined> {
  const db = await dbFactory.getDb();
  if (userFilter) {
    return userFilter.filters.find((filter) => filter.title.toLowerCase() === title.toLowerCase());
  }
  const userName = getUserName();
  const personalFilters = await db.get(UserPreferencesStore, userName);
  if (personalFilters) {
    return parseIntoUserPersonalFilters(personalFilters)?.filters.find((filter) => filter.title.toLowerCase() === title.toLowerCase());
  }
  return undefined;
}

async function upsertFilter(newFilter: PersonalFilter, overWriteDefault: boolean, isNew: boolean): Promise<boolean> {
  const db = await dbFactory.getDb();
  const userName = getUserName();
  const personalFilters = await db.get(UserPreferencesStore, userName);

  if (personalFilters) {
    const existingFilter = parseIntoUserPersonalFilters(personalFilters);
    if (existingFilter) {
      const casSetDefault=await handleDefaultFilter(newFilter, existingFilter, overWriteDefault);
      if(!casSetDefault) {
        return false;
      }
      const canUpdate = await updateExistingFilter(newFilter, existingFilter, isNew);
      if (!canUpdate) {
        return false;
      }
      await saveFilters(existingFilter);
    }
  } else {
    await saveFilters({
      userID: userName,
      filters: [newFilter],
    });
  }

  return true;
}

async function handleDefaultFilter(
  newFilter: PersonalFilter,
  existingFilter: UserPersonalFilters,
  overWriteDefault: boolean
): Promise<boolean> {
  if (newFilter.isDefault) {
    const existingDefaultFilter = await getDefaultFilter(existingFilter);
    if (existingDefaultFilter) {
      if (!overWriteDefault) {
        return false;
      }
      existingDefaultFilter.isDefault = false;
    }
  }
  return true;
}

async function updateExistingFilter(
  newFilter: PersonalFilter,
  existingFilter: UserPersonalFilters,
  isNew: boolean
): Promise<boolean> {
  const existingFilterWithTitle = await findFilterByTitle(newFilter.title, existingFilter);
  if (existingFilterWithTitle) {
    if (isNew) {
      return false;
    }
    existingFilterWithTitle.title = newFilter.title;
    existingFilterWithTitle.isDefault = newFilter.isDefault;
    existingFilterWithTitle.selectedFilters = newFilter.selectedFilters;
  } else {
    existingFilter?.filters.push(newFilter);
  }
  return true;
}

async function deleteFilter(title: string ): Promise<boolean> {
  const db = await dbFactory.getDb();
  const userName = getUserName();
  const personalFilters = await db.get(UserPreferencesStore, userName);
  if (personalFilters) {
    const index = personalFilters.filters.findIndex((filter: PersonalFilter) => filter.title.toLowerCase() === title.toLowerCase());
    if (index == -1) {
       return false
    };
    personalFilters.filters.splice(index, 1);
    await saveFilters(personalFilters); 
    return true;
  }
  return false;
}

export const filterStorage = { upsertFilter, findFilterByTitle, getDefaultFilter, loadFilters, deleteFilter };
