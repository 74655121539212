import { Locale } from '@customTypes/LocalLanguages';

export type Settings = {
  localLanguage: Locale;
  layout: UiLayouts;
  negotiationCountry: string;
};

export enum UiLayouts {
  core_ui,
  onyx_ui,
}

export type UserSettings = {
  userID: string;
  settings: Settings;
};
