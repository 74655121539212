import { User } from 'oidc-client-ts';
import { PersonalFilter } from '@customTypes/FilterTypes';

export const UserKeyField="userID"
export const UserPreferencesStore="userPreferences";

export type PsfCustomAttributes = {
  countries?: string[];
};

export type SiamUser = User & {
  profile: {
    fullName?: string;
    groupMembership?: string[];
    land?: string;
    roles?: string[];
    psf_custom_attributes?: PsfCustomAttributes[] | PsfCustomAttributes;
  };
};

export type UserPersonalFilters = {
  userID: string;
  filters: PersonalFilter[];
};
