import { defineStore } from 'pinia';
import { ref } from 'vue';
import { SelectedFilters, AddFilterParameters } from '@customTypes/FilterTypes';
import { filterStorage } from '@services/storage/filterStorage';
import { UserPersonalFilters } from '@customTypes/User';

export const useFilterStore = defineStore(' ', () => {
  const initialFilterSelection: SelectedFilters = {
    suppliers: [],
    itemFamilies: [],
    purchasingManagers: [],
  };

  const selectedSavedFilter = ref<string | undefined>(undefined);
  const currentSelectedFilters = ref({ ...initialFilterSelection });

  const userPersonalFilters = ref<UserPersonalFilters | undefined>(undefined);

  // load personal filters from database
  async function loadPreferences() {
    userPersonalFilters.value = await filterStorage.loadFilters();
  }

  // Add a new filter
  async function addFilter(parameters: AddFilterParameters) {
    await filterStorage.upsertFilter(
      {
        isDefault: parameters.isDefault,
        title: parameters.title.trim(),
        selectedFilters: currentSelectedFilters.value,
      },
      parameters.overWriteDefault,
      parameters.isNew
    );
    await loadPreferences();
  }

  // Select a filter
  async function selectFilter(title: string | undefined) {
    selectedSavedFilter.value = title;
    await onSavedFilterChange(title);
  }

  const onSavedFilterChange = async (changeUserFilter: string | undefined) => {
    if (changeUserFilter) {
      const personalFilterRequest = await filterStorage.findFilterByTitle(changeUserFilter);
      currentSelectedFilters.value = personalFilterRequest
        ? personalFilterRequest.selectedFilters
        : { ...initialFilterSelection };
    } else {
      currentSelectedFilters.value = { ...initialFilterSelection };
    }
  };

  // clear current Selection
  function clearFilterSelection() {
    currentSelectedFilters.value = { ...initialFilterSelection };
    selectFilter(undefined);
  }

  // Select a default filter
  async function selectDefaultFilter() {
    await loadPreferences();
    const defaultFilter = userPersonalFilters.value?.filters.find((filter) => filter.isDefault);
    await selectFilter(defaultFilter?.title);
  }

  async function deleteFilter(parameters: string) {
    await filterStorage.deleteFilter( parameters );
    await loadPreferences();
  }

  return {
    userPersonalFilters,
    selectFilter,
    selectedSavedFilter,
    currentSelectedFilters,
    addFilter,
    clearFilterSelection,
    selectDefaultFilter,
    deleteFilter
  };
});


