import { openDB, IDBPDatabase } from 'idb';
import { UserKeyField, UserPreferencesStore } from '@customTypes/User';

const dbName= 'kkf'
const dbVersion=1;

async function getDb(): Promise<IDBPDatabase> {
    return openDB(dbName, dbVersion, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(UserPreferencesStore)) {
          db.createObjectStore(UserPreferencesStore,{ keyPath: UserKeyField} );
        }
      }
    });
}

export const dbFactory = { getDb};
